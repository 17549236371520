import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



const CustomTable = () => {
  const [customersData, setCustomersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [reload, setreload] = useState(false);

  const [genderFilter, setGenderFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [sortingOn, setSortingOn] = useState('name');
  const [sortingMethod, setSortingMethod] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const rowsPerPage = 20;
useEffect(()=>{
  setCurrentPage(1)
},[searchTermFilter])



  // getting customers data from database
  const WebapiURL = process.env.REACT_APP_API_URL_WebURL;
  const [admissions, setAdmissions] = useState("");

  const GetAdmissions = async () => {
    setreload(true)
      await fetch(`${WebapiURL}/admission-enquiries`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json"
          }
      }).then((response) => response.json()).then((data) => {
          console.log(data)
          setTimeout(() => {
            
              setreload(false)
          }, 500);
          if (data.status === true) {
            setCustomersData(data.data);
          }
      });
  }
  useEffect(() => {
      GetAdmissions();

  }, []);


  // FILTERING DATA IN ONE GO
  useEffect(() => {
    // filtering according to genderFilter
    const tempCustomersData = customersData;
    // filtering according to searchTermFilter

    const tempSearchTermFilterData = tempCustomersData.filter(item =>
        Object.values(item).some(value => {
          if (value !== null && value !== undefined) {
            return value.toString().toLowerCase().includes(searchTermFilter.toLowerCase());
          }
          return false;
        })
      );


    setFilteredData(tempSearchTermFilterData);
  }, [customersData, genderFilter, roleFilter, searchTermFilter, statusFilter]);

  // sorting searchTermFilteredData according to sortingOn and sortingMethod
  useEffect(() => {
    const tempFilteredData = filteredData;

    const asc = (a, b) => {
      if (
        String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
      )
        return 1;
      else if (
        String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
      )
        return -1;
      else return 0;
    };
    const des = (a, b) => {
      if (
        String(a[sortingOn]).toLowerCase() < String(b[sortingOn]).toLowerCase()
      )
        return 1;
      else if (
        String(a[sortingOn]).toLowerCase() > String(b[sortingOn]).toLowerCase()
      )
        return -1;
      else return 0;
    };

    tempFilteredData.sort(sortingMethod ? asc : des);
    setSortedData(tempFilteredData);
  }, [filteredData, sortingMethod, sortingOn]);

  // paginating sortedData accordint to currentPage and rowsPerPage
  useEffect(() => {
    const indexOfLastUser = currentPage * rowsPerPage;
    const indexOfFirstUser = indexOfLastUser - rowsPerPage;
    setPaginatedData(sortedData.slice(indexOfFirstUser, indexOfLastUser));
  }, [currentPage, sortedData, rowsPerPage, sortingMethod]);

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    const totalPage = Math.ceil(sortedData.length / rowsPerPage);
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  // downloading data in Excel / CSV format
  const handleDownloadData = () => {
    const items = sortedData;
  
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  
    // Define the header mapping
    const headerMapping = {
      name: "Name",
      contactNumber: "Contact Number",
      email: "Email",
      dateOfBirth: "Date of Birth",
      fathersName: "Father's Name",
      alternateContactNumber: "Father Contact Number", // Changed header name
      address: "Address",
      course: "Course",
      message: "Message"
    };
  
    // Create the headers array from the header mapping values
    const header = Object.values(headerMapping);
  
    // Create the CSV content
    const csv = [
      header.join(','), // header row first
      ...items.map((row) =>
        Object.keys(headerMapping)
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
      ),
    ].join('\r\n');
  
    // Create link and download
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURI(csv));
    link.setAttribute('download', 'User-Details.csv'); // Added .csv extension for clarity
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <>
    
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  <i className="nav-icon fas fa-users me-2" />
                  Admission Forms
                </h1>
              </div>
              
            </div>


            <div className="card mt-5 " >
              <div className="card-header d-flex">
                <input
                  type="text"
                  className="form-control flex-grow-1"
                  placeholder="Search for name, email, phone no"
                  autoFocus={true}
                  value={searchTermFilter}
                  onChange={(e) => {
                    setSearchTermFilter(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="btn btn-dark ms-2 d-flex align-items-center"
                  onClick={GetAdmissions}
                >
                  {reload ? <i class="spinner-border spinner-border-sm"></i>
                  :
                  <i class="fa-solid fa-rotate-right"></i>}
                  
                </button>
             
                <button
                  type="button"
                  className="btn btn-dark ms-2 d-flex align-items-center"
                  onClick={handleDownloadData}
                >
                  <i className="fa fa-cloud-download me-1" aria-hidden="true" />
                  Download
                </button>
              </div>
              <div className="card-body" style={{ overflowX: 'scroll' }}>
                <table
                  className="table table-hover"
                  style={{ minWidth: '840px' }}
                >
                  <thead className="table-light">
                    <tr>
                      <th scope="col" className="w-100 border">#</th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSortingMethod(!sortingMethod);
                          setSortingOn('name');
                        }}
                      >
                        Name
                        <i className="ms-2 fa fa-sort" aria-hidden="true" />
                      </th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSortingMethod(!sortingMethod);
                          setSortingOn('name');
                        }}
                      >
                        Email
                        <i className="ms-2 fa fa-sort" aria-hidden="true" />
                      </th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSortingMethod(!sortingMethod);
                          setSortingOn('name');
                        }}
                      >
                        Phone no.
                        <i className="ms-2 fa fa-sort" aria-hidden="true" />
                      </th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        
                      >
                      DOB
                      </th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        
                      >
                      father's Name
                      </th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        
                      >
                      father's Phone Number
                      </th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        
                      >
                      course
                      </th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        
                      >
                      address
                      </th>
                      <th
                        scope="col"
                        className="w-100 border"
                        style={{ cursor: 'pointer' }}
                        
                      >
                      messages
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                    {paginatedData.length === 0 ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No data
                        </td>
                      </tr>
                    ) : (
                      paginatedData.map((data, index) => {
                        return (
                          <tr key={data._id} className='tabledata'>
                            <th scope="row">
                              {currentPage * rowsPerPage -
                                rowsPerPage +
                                index +
                                1}
                            </th>
                            <td>{data.name}</td>
                            <td>{data.email}</td>
                            <td>{data.contactNumber}</td>
                            <td>{data.dateOfBirth}</td>
                            <td>{data.fathersName}</td>
                            <td>{data.alternateContactNumber}</td>
                            <td>{data.course}</td>
                            <td>{data.address}</td>
                            <td>{data.message}</td>
                           
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-primary me-2"
                  onClick={prevPage}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </button>
                <input
                  type="text"
                  disabled={true}
                  className="form-control"
                  style={{ width: '100px', textAlign: 'center' }}
                  // value={`${currentPage}/${
                  //   Math.ceil(customersData.length / rowsPerPage) || 1
                  // }`}
                  value={`${currentPage}/${
                    Math.ceil((searchTermFilter !== '' ? filteredData.length : customersData.length) / rowsPerPage) || 1
                }`}
                  readOnly={true}
                />
                <button
                  type="button"
                  className="btn btn-outline-primary ms-2"
                  onClick={nextPage}
                >
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
 
    </>
  );
};

export default CustomTable;
