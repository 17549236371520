import React, { useEffect, useState } from 'react';
import './Form.css';
import axios from 'axios';
import swal from "sweetalert";

const FacultyInput = ({ refCloseImage, updateInfo, GetSubServices, location, slider_imagesID, objectDetails, refOpenInfo, refOpenImage, setSubTitle, setTitle }) => {

  const apiURL = process.env.REACT_APP_API_URL_LIVE;
  const localData = {
    image: [],
    name: '',
    department: '',
    designation:'',
    email: '',
    phone: '',
    profile: '',
    qualifications: '',
    experience: '',
    publications: '',
    specializations: '',
    achievements:''
  };

  const [formData, setFormData] = useState(localData);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setFormData({
        ...formData,
        [name]: files ? [...files] : value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  useEffect(() => {
    const isValidJSON = (str) => {
      if (typeof str !== 'string') return false;
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };

    let parse = objectDetails?.description;
    let parseData = isValidJSON(parse) ? JSON.parse(parse) : localData;

    if (!parseData) {
      parseData = objectDetails?.description;
    }
    console.log(parseData);
    setFormData(parseData);
 
  }, [objectDetails, updateInfo]);

  const updateInformation = async () => {
    try {
      const data = new FormData();
      data.append("subServiceId", location._id);
      data.append("images", formData.image[0] || null);
      data.append("subtitle", formData.department);
      data.append("title", formData.name);
      data.append("description", JSON.stringify(formData));
      data.append("imageId", slider_imagesID);
      const response = await axios.patch(`${apiURL}/updateSliderImage`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      GetSubServices(location.service_id);
      swal("Success", response?.data?.message, "success");
      refOpenInfo.current.click();
    } catch (error) {
      swal('Upload error:', error);
    }
  };

  const updateImages = async () => {
    try {
      const data = new FormData();
      data.append("subServiceId", location._id);
      data.append("subtitle", formData.department || "");
      data.append("title", formData.name || "");
      data.append("description", JSON.stringify(formData) || "");
      Array.from(formData.image).forEach((file) => {
        data.append("images", file);
      });
      const response = await axios.post(`${apiURL}/addSlider`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      swal("Success", response.data.message, "success").then((ok) => {
        if (ok) {
          refCloseImage.current.click();
          refOpenImage.current.click();
          GetSubServices(location.service_id);
          setFormData(localData);
        }
      });
    } catch (error) {
      swal('Upload error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (updateInfo === "info") {
      updateInformation();
    } else {
      updateImages();
    }
  };
  console.log(formData);

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="form-group">
        <label htmlFor="image">Upload Image</label>
        <input type="file" id="image" name="image" multiple={updateInfo !== "info"} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" value={formData?.name} onChange={handleChange} required />
      </div>    
       <div className="form-group">
        <label htmlFor="name">Designation</label>
        <input type="text" id="name" name="designation" value={formData?.designation} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" value={formData?.email} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone</label>
        <input type="tel" id="phone" name="phone" value={formData?.phone} onChange={handleChange} required />
      </div>
      <div className="form-group">
        <label htmlFor="profile">Profile</label>
        <input type="text" id="profile" name="profile" value={formData?.profile} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label htmlFor="department">Department</label>
        <input type="text" id="department" name="department" value={formData?.department} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="specializations">Specializations</label>
        <textarea id="specializations" name="specializations" value={formData?.specializations} onChange={handleChange}></textarea>
      </div>
      <div className="form-group">
        <label htmlFor="experience">Experience</label>
        <textarea id="experience" name="experience" value={formData?.experience} onChange={handleChange} required></textarea>
      </div>
      <div className="form-group">
        <label htmlFor="qualifications">Qualifications</label>
        <textarea id="qualifications" name="qualifications" value={formData?.qualifications} onChange={handleChange} required></textarea>
      </div>
     
      <div className="form-group">
        <label htmlFor="publications">Publications</label>
        <textarea id="publications" name="publications" value={formData?.publications} onChange={handleChange}></textarea>
      </div>
     
        <div className="form-group">
        <label htmlFor="achievements">Achievements</label>
        <textarea id="achievements" name="achievements" value={formData?.achievements} onChange={handleChange}></textarea>
      </div>
      <button className='btn btn-primary' type="submit">Submit</button>
    </form>
  );
}

export default FacultyInput;
