import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const useToken = () => {

    const getToken = () => {
        const tokenString = secureLocalStorage.getItem("tokens");
        return JSON.parse(tokenString);
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        secureLocalStorage.setItem("tokens", JSON.stringify(userToken));
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token,
    };
}

export default useToken;