import React, { useState } from 'react';
import {
    ChakraProvider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button,
    Input,
    Stack
} from '@chakra-ui/react';

function Questions({questions, setQuestions}) {
    
    console.log(questions);

    const addQuestion = () => {
        setQuestions([...questions, { question: "", subquestions: [] }]);
    };

    const handleQuestionChange = (e, idx) => {
        const newQuestions = [...questions];
        newQuestions[idx].question = e.target.value;
        setQuestions(newQuestions);
    };

    const addSubquestion = (qIdx) => {
        const newQuestions = [...questions];
        newQuestions[qIdx].subquestions.push({ subquestions: "", subAnswer: "" });
        setQuestions(newQuestions);
    };

    const handleSubquestionChange = (e, qIdx, sqIdx) => {
        const newQuestions = [...questions];
        newQuestions[qIdx].subquestions[sqIdx].subquestions = e.target.value;
        setQuestions(newQuestions);
    };

    const addSubanswer = (qIdx, sqIdx) => {
        const newQuestions = [...questions];
        const subAnswer = newQuestions[qIdx].subquestions[sqIdx].subAnswer;
        if (typeof subAnswer === "string") {
            newQuestions[qIdx].subquestions[sqIdx].subAnswer = [{ courseType: "", courseAnswer: "" }];
        } else {
            newQuestions[qIdx].subquestions[sqIdx].subAnswer.push({ courseType: "", courseAnswer: "" });
        }
        setQuestions(newQuestions);
    };

    const handleSubanswerChange = (e, qIdx, sqIdx, saIdx, field) => {
        const newQuestions = [...questions];
        const subAnswer = newQuestions[qIdx].subquestions[sqIdx].subAnswer;
        if (Array.isArray(subAnswer)) {
            subAnswer[saIdx][field] = e.target.value;
        } else {
            newQuestions[qIdx].subquestions[sqIdx].subAnswer = e.target.value;
        }
        setQuestions(newQuestions);
    };

    return (
        <ChakraProvider>
            <Box  >
                
                <Accordion allowMultiple w={500}>
                    {questions.map((q, qIdx) => (
                        <AccordionItem key={qIdx}>
                            <AccordionButton  >
                                <Box flex="1" textAlign="left">
                                    <Input
                                        type="text"
                                        value={q.question}
                                        onChange={(e) => handleQuestionChange(e, qIdx)}
                                        placeholder="Question"
                                    />
                                </Box>
                                <Button color={'green'} onClick={() => addSubquestion(qIdx)} mt={2} ml={5}>+ sub</Button>
                                <AccordionIcon ml={5} />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                               
                                {q.subquestions.map((sq, sqIdx) => (
                                    <AccordionItem key={sqIdx}>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                <Input
                                                    type="text"
                                                    value={sq.subquestions}
                                                    onChange={(e) => handleSubquestionChange(e, qIdx, sqIdx)}
                                                    placeholder="Subquestion"
                                                />
                                            </Box>
                                            <Button onClick={() => addSubanswer(qIdx, sqIdx)} mb={2} ml={4}>Add Course</Button>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel >
                                            
                                            {Array.isArray(sq.subAnswer) ? (
                                                sq.subAnswer.map((sa, saIdx) => (
                                                    <Stack   mt={3} spacing={0} key={saIdx}  ml={1}>
                                                        <Input
                                                            type="text"
                                                            value={sa.courseType}
                                                            onChange={(e) => handleSubanswerChange(e, qIdx, sqIdx, saIdx, 'courseType')}
                                                            placeholder="Course Type"
                                                        />
                                                        <Input
                                                            type="text"
                                                            value={sa.courseAnswer}
                                                            onChange={(e) => handleSubanswerChange(e, qIdx, sqIdx, saIdx, 'courseAnswer')}
                                                            placeholder="Course Answer"
                                                        />
                                                    </Stack>
                                                ))
                                            ) : (
                                                <Input
                                                    type="text"
                                                    value={sq.subAnswer}
                                                    onChange={(e) => handleSubanswerChange(e, qIdx, sqIdx, null, 'subAnswer')}
                                                    placeholder="SubAnswer"
                                                />
                                            )}
                                        </AccordionPanel>
                                    </AccordionItem>
                                ))}
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
                <Button onClick={addQuestion} mb={1}>Add Question</Button>
            </Box>
        </ChakraProvider>
    );
}

export default Questions;
