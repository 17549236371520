import React, { useState, useRef } from "react";
import swal from "sweetalert";

const AddTitle = (props) => {

    const { apiURL, serviceId } = props;

    const [title, setTitle] = useState("");
    const refClose = useRef(null);

    const AddTitle = async (e) => {

        e.preventDefault();

        // API Call
        await fetch(`${apiURL}/addTitle`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serviceId: serviceId,
                title: title
            })
        }).then((res) => {
            res.json().then((data) => {
                const status = data.status;
                setTitle("") 
                if (status === true) {
                    swal("Success", data.message, "success").then((ok) => {
                        if (ok) {
                            refClose.current.click();
                        }
                    });
                } else {
                    swal("Error", data.message, "error");
                }
            });
        })
    };

    return (
        <>
            <button type="button" className="btn btn-dark btn-round" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas4" aria-controls="depOffCanvas4"><i className="fa fa-plus" aria-hidden="true"></i> Add Title </button>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="depOffCanvas4" aria-labelledby="depOffCanvas4">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Title</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refClose}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3" onSubmit={AddTitle}>
                            <div className="col-md-12 text-left">
                                <label htmlFor="title" className="form-label font-weight-bold">
                                    Title
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    value={title}
                                    onChange={(e) => { setTitle(e.target.value) }}
                                    placeholder="Ex: Title"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-dark btn-block btn-round" disabled={title.length < 0}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTitle;