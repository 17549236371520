import React, { useState, useRef, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from 'axios';
import imageSrc from "./pdf-image.png";
import FacultyInput from "./FacultyInput";


    const UploadImages = (props) => {
        const image_url = process.env.REACT_APP_API_URL_IMAGE;


        const { apiURL } = props;
        const fileInputRef = useRef(null);

        const localdata={
            subtitle:"",
            title:"",
            description:"",
            images:null
        }
        const [uploadImage, setUploadImage] = useState(localdata);
        const [isLoggedIn, setLoggedIn] = useState(false);

        const [title, setTitle] = useState('');
        const [subTitle, setSubTitle] = useState('');
        const [description, setDescription] = useState('');
        const [slider_imagesID, setSliderImagesID] = useState('');
        const [sliderimages, setUpdateImage] = useState(null);
        const[objectDetails,setObjectDetails]=useState(null)
         const[updateInfo,setUpdateInfo]=useState(null)

        const refOpenImage = useRef(null);
        const refOpenInfo = useRef(null);
        const refCloseImage = useRef(null);

        const location = useLocation().state.element;
        const from =  useLocation().state.from
        console.log(from);

        const [slider_images, setSliderImages] = useState([]);
    console.log(slider_images)





    const GetSubServices = async (id) => {
    try {
        const response = await axios.get(`${apiURL}/subserviceList/${id}`, {
        headers: {
            "Content-Type": "application/json"
        }
        });

        if (response.data.status === true) {
        // setSubServices(response.data.data);
        const servicedata = response.data.data
        console.log("subservices", response.data.data);
        let Adddata =servicedata.find(x=>x._id === location._id) || []
        console.log(Adddata.slider_images)
        setSliderImages(Adddata.slider_images)
        } else {
        console.error("Error: ", response.data.message);
        }
    } catch (error) {
        console.error("Fetch error: ", error.message);
    }
    };


    console.log(location)










        useEffect(() => {
            GetSubServices(location.service_id)
            // setSliderImages(location.slider_images);
        }, [location.slider_images]);

        // alert("sss ", location.length);
        const navigate = useNavigate();

        const handleFileChange = (event) => {
            // const files = Array.from(event.target.files);
            // setUploadImage(files);
            const name = event.target.name
            const value = event.target.value
            const files = event.target.files
            console.log(files)
            setUploadImage((pre)=>
                ({ ...pre,[name]:name === "images" ? files : value }));

        };

        const handleImages = () => {
            setUpdateInfo('images')
            refOpenImage.current.click();
        }

        const handleInfo = (sliderImageID) => {
            setUpdateInfo('info')
            setSliderImagesID(sliderImageID);
            refOpenInfo.current.click();
        }

        const handleInformation = async (slider_imagesID) => {
            //if (slider_images && slider_images !== null && slider_imagesID) {
            var findObject = slider_images.find(item => item._id === slider_imagesID);
            setTitle(findObject.title);
            setSubTitle(findObject.subtitle);
            setDescription(findObject.description);
            setObjectDetails(findObject)
            // }  
        }

        const UploadImage = async (e) => {
            setLoggedIn(true);
            e.preventDefault();
            // API Call
            const data = new FormData();
            data.append("subServiceId", location._id);
            // data.append("images", uploadImage.images[0]);
            data.append("subtitle", uploadImage.subtitle || "");
            data.append("title", uploadImage.title || "");
            data.append("description", uploadImage.description || "");
            console.log(uploadImage)
            Object.values(uploadImage.images).forEach((file) => {
                console.log(file)
            
                data.append("images", file);
            
                
            
            
            });

            

            try {

                const response = await axios.post(`${apiURL}/addSlider`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });


                const status = response.data.status;
                if (status === true) {
                    setLoggedIn(false);
                    GetSubServices(location.service_id)
                    swal("Success", response.data.message, "success").then((ok) => {
                        if (ok) {
                            refCloseImage.current.click();
                            refOpenImage.current.click();
                            fileInputRef.current.value = null; // Clear the file input
                            setUploadImage(localdata)
                            // navigate("/");
                        }
                    });
                } else {
                    setLoggedIn(false);
                    swal("Error", response.data.message, "error");
                }


            } catch (error) {
                setLoggedIn(false);
                // Handle error response
                swal('Upload error:', error);
            }




            /*
            await fetch(`${apiURL}/addSlider`, {
                method: "POST",
                body: data
            }).then((res) => {
                res.json().then((data) => {
                    const status = data.status;
                    if (status === true) {
                        setLoggedIn(true);
                        swal("Success", data.message, "success").then((ok) => {
                            if (ok) {
                                refCloseImage.current.click(); 
                                navigate("/");
                            }
                        });
                    } else {
                        swal("Error", data.message, "error");
                    }
                });
            });

            */
        };

        // const UploadInfo = async (e) => {
        //     e.preventDefault();
        //     // API Call
        //     const serviceID = location._id;
        //     const data = {
        //         title,
        //         subTitle,
        //         description,
        //         serviceID,
        //         slider_imagesID
        //     }

        //     try {
        //         const response = await axios.post(`${apiURL}/addSliderContent`, data, {
        //             headers: {
        //                 'Content-Type': 'multipart/form-data',
        //             },
        //         });
        //         const status = response.data.status;
        //         if (status === true) {
        //             setLoggedIn(true);
        //             swal("Success", response.data.message, "success").then((ok) => {
        //                 if (ok) {
        //                     refOpenInfo.current.click();
        //                     navigate("/");
        //                 }
        //             });
        //         } else {
        //             setLoggedIn(false);
        //             swal("Error", response.data.message, "error");
        //         }
        //     } catch (error) {
        //         setLoggedIn(false);
        //         swal('Upload error:', error);
        //     }
        // };
        const UploadInfo = async (e) => {
            
            e.preventDefault();
            setLoggedIn(true);
            // API Call
            const data = new FormData();
            data.append("subServiceId", location._id);
            data.append("images", sliderimages || null);
            data.append("subtitle", subTitle);
            data.append("title", title);
            data.append("description", description);
            data.append("imageId", slider_imagesID);

            try {

                const response = await axios.patch(`${apiURL}/updateSliderImage`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(response)
                const status = response.data.status;
                console.log(status)
                if (status === true) {
                
                    GetSubServices(location.service_id)
                    swal("Success", response?.data?.message, "success")
                    refOpenInfo.current.click();
                
                } else {
                
                    swal("Error", response.data.message, "error");
                }
                setLoggedIn(false);
            } catch (error) {
                setLoggedIn(false);
                // Handle error response
                swal('Upload error:', error);
            }

        };

        const DeleteSlider = async (id, imageName) => {
            console.log(id)
            console.log(imageName)
            // API Call
            await fetch(`${apiURL}/deleteSliderImage`, {
                method: "delete",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    subServiceId: location._id,
                    imageName: imageName,
                    imageId: id
                })
            }).then((res) => {
                res.json().then((data) => {
                    const status = data.status;
                    if (status === true) {
                        swal("Success", data.message, "success").then((ok) => {
                            if (ok) {
                                GetSubServices(location.service_id)
                                // var updateSliderImages = slider_images.filter(item => item !== imageName);
                                // var updateSliderImages = slider_images.filter(item => item.id !== id);

                                // setSliderImages(updateSliderImages);


                                // window.location.reload();
                                // navigate("/");
                            }
                        });
                    } else {
                        swal("Error", data.message, "error");
                    }
                });
            })
        };

        // console.log("slider_images  ---- ", slider_images);

        return (
            <>
                <button ref={refOpenImage} type="button" className="btn btn-dark d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas4" aria-controls="depOffCanvas4"></button>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id="depOffCanvas4" aria-labelledby="depOffCanvas4" style={{ overflow:'scroll'}}>
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvas1Label">Upload Images</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refCloseImage}></button>
                    </div>
                    {from==="Faculties"? <FacultyInput refCloseImage={refCloseImage} updateInfo={updateInfo} GetSubServices={GetSubServices} refOpenImage={refOpenImage} slider_imagesID={slider_imagesID} location={location}></FacultyInput> :<div className="offcanvas-body">
                        <div>
                            <form className="row g-3" onSubmit={UploadImage}>
                                <div className="col-md-12 text-left">
                                <label htmlFor="title" className="form-label font-weight-bold">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        value={uploadImage.title}
                                        onChange={handleFileChange}
                                        placeholder="Enter title"
                                        autoComplete="off"
                                    />
                                    

                                    <label htmlFor="Subtitle" className="form-label font-weight-bold mt-2">
                                        Subtitle
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Subtitle"
                                        name="subtitle"
                                        value={uploadImage.subtitle}
                                        onChange={handleFileChange}
                                        placeholder="Enter Subtitle"
                                        autoComplete="off"
                                    />

                                    <label htmlFor="description" className="form-label font-weight-bold mt-2">
                                    Description
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        value={uploadImage.description}
                                        onChange={handleFileChange}
                                        placeholder="Enter description"
                                        autoComplete="off"
                                    />

                                    <label htmlFor="uploadImage" className="form-label font-weight-bold mt-2">
                                        Upload Image
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="images"
                                        id="uploadImage"
                                        onChange={handleFileChange}
                                        multiple
                                        ref={fileInputRef}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-dark btn-block btn-round">
                                        {isLoggedIn ? 'Uploading...' : 'Submit'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>}
                </div>

                {/* popop for update info start*/}

                <button ref={refOpenInfo} type="button" className="btn btn-dark d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas5" aria-controls="depOffCanvas5"></button>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id="depOffCanvas5" aria-labelledby="depOffCanvas5">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvas1Label">Upload Info</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refCloseImage}></button>
                    </div>
                    <div className="offcanvas-body">
                        <div>
                        {from==="Faculties" ? <><FacultyInput refCloseImage ={refCloseImage} updateInfo={updateInfo} GetSubServices={GetSubServices} setSubTitle={setSubTitle} setTitle={setTitle}  refOpenInfo={refOpenInfo} objectDetails={objectDetails} slider_imagesID={slider_imagesID} location={location}/></> :
                        
                            <form className="row g-3" onSubmit={UploadInfo}>
                                <div className="col-md-12 text-left">
                                    <label htmlFor="title" className="form-label font-weight-bold">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        id="title"
                                        value={title}
                                        onChange={(e) => { setTitle(e.target.value) }}

                                    />
                                </div>

                                <div className="col-md-12 text-left">
                                    <label htmlFor="subTitle" className="form-label font-weight-bold">
                                        Sub Title
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="subTitle"
                                        id="subTitle"
                                        value={subTitle}

                                        onChange={(e) => { setSubTitle(e.target.value) }}

                                    />
                                </div>

                                <div className="col-md-12 text-left">
                                    <label htmlFor="Description" className="form-label font-weight-bold">
                                        Description
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name="description"
                                        id="Description"
                                        value={description}
                                        onChange={(e) => { setDescription(e.target.value) }}

                                    >{description}</textarea>
                                </div>

                                <label htmlFor="uploadImage" className="form-label font-weight-bold mt-2">
                                        Upload Image
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="images"
                                        id="uploadImage"
                                        onChange={(e) => { setUpdateImage(e.target.files[0]) }}
                                        multiple
                                    />


                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-dark btn-block btn-round">
                                        {isLoggedIn ? 'Uploading...' : 'Update'}
                                    </button>
                                </div>
                            </form>
                         }
                           
                        </div>
                    </div>
                </div>
                {/* end */}

                <div className="container-fluid mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-left">
                                <h4>Upload Images</h4>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <NavLink to="/" style={{ fontSize: "18px", color: "black", textDecoration: "none" }}>Dashboard</NavLink>
                                    </li>
                                    <li className="breadcrumb-item">
                                        {/* <NavLink to="#!" style={{ fontSize: "18px", color: "black", textDecoration: "none" }}>Upload Images</NavLink> */}
                                        <NavLink to="/" style={{ fontSize: "18px", color: "black", textDecoration: "none" }}>Upload Images</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">
                                <div className="card-header-right">
                                    <div className="dropdown">
                                        <div className="nav-link active dropdown-toggle text-black" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Action
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" onClick={() => handleImages()}>
                                                    <i className="fa-solid fa-check-double"></i> Update Images</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {
                                (slider_images) && (slider_images !== null) ? <div className="row mt-4">
                                    {
                                        slider_images.map((element, index) => {
                                            var extension = '';
                                            if (element.imageUrl && element.imageUrl !== '') {
                                                extension = element.imageUrl.split(".").pop(); // Get the file extension
                                            }


                                            return (
                                                <div className="col-md-4" key={index}>
                                                    <div className="ms-3">
                                                        <div className="card hoverable-dropdown" style={{ width: "20rem" }}>
                                                            <div style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0" }}>
                                                                <span className="badge bg-dark rounded-pill span-show">
                                                                    <div className="btn-group">
                                                                        <div
                                                                            className="btn-sm cp"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                        </div>
                                                                        <div className="dropdown-menu cp">
                                                                            <div className="dropdown-item" onClick={() =>
                                                                                swal("Warning", "Are you sure you want to delete this image", "warning", {
                                                                                    buttons: ["No", "Yes"]
                                                                                }).then((value) => {
                                                                                    if (value) {
                                                                                        DeleteSlider(element._id, element.imageUrl);
                                                                                    }
                                                                                })}>
                                                                                Delete Image
                                                                            </div>
                                                                            <div className="dropdown-item" onClick={() => {
                                                                                handleInformation(element._id)
                                                                                handleInfo(element._id)
                                                                            }} >
                                                                                Update Info
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <a href={`${image_url}/${element.imageUrl}`} data-fancybox="group">
                                                                {extension === "pdf" ? (
                                                                    <img
                                                                        style={{ height: "200px" }}
                                                                        className="card-img-top"
                                                                        src={`${imageSrc}`}
                                                                        alt={location.title}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        style={{ height: "200px" }}
                                                                        className="card-img-top"
                                                                        src={`${image_url}/${element.imageUrl}`}
                                                                        alt={location.title}
                                                                    />
                                                                )}
                                                                {/* <img style={{ height: "200px" }} className="card-img-top" src={`${image_url}/${element.imageUrl}`} alt={location.title} /> */}
                                                            </a>
                                                            <div className="py-2 px-3">
                                                                {element.title && <h5 className="fw-bold">{element.title}</h5>}
                                                                {element.subtitle && <h6>{element.subtitle}</h6>}
                                                                {/* {element.description && <p style={{ width: '100%', lineHeight: '20px' }}>{element.description}</p>} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })

                                    }
                                </div>

                                    :
                                    <div className="card-body">
                                        <h5 className="card-title text-center">No data found</h5>
                                    </div>
                            }
                            <div className="card-footer"></div>
                        </div>
                    </div>

                </div>
            </>
        )
    }

export default UploadImages;