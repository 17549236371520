import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
import Avatar from "react-avatar";
import secureLocalStorage from "react-secure-storage";
import "./TopNavbar.css";

const TopNavbar = (props) => {

    const { apiURL, setToken, setIsLoggedIN } = props;

    const parseRole = secureLocalStorage.getItem("data");
    const role = JSON.parse(parseRole).data;

    const handleLogout = () => {
        secureLocalStorage.removeItem("tokens");
        secureLocalStorage.removeItem("data");
        window.location.reload();
    };

    const [addServices, setAddServices] = useState({ serviceName: "", dropDown: "" });
    const refOpenServices = useRef(null);
    const refCloseServices = useRef(null);

    const handleServices = () => {
        refOpenServices.current.click();
    }

    const changeAddServices = (e) => {
        setAddServices({ ...addServices, [e.target.name]: e.target.value });
    }

    const AddServices = async () => {

        // API Call
        await fetch(`${apiURL}/addService`, {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                serviceName: addServices.serviceName,
                dropDown: addServices.dropDown
            })
        }).then((res) => {
            res.json().then((data) => {
                if (data.status === true) {
                    swal("Success", data.message, "success").then((ok) => {
                        if (ok) {
                            refCloseServices.current.click();
                            window.location.reload();
                        }
                    })
                } else {
                    swal("Error", data.message, "error");
                }
            })
        })
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <NavLink className="navbar-brand mr-5" to="/">
                        <img src="assets\images\logo.png" style={{ width: "50px", marginLeft: "50px" }} alt="logo" />
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"><li className="nav-item me-2"><NavLink className="navbar-brand mr-5" to="/admission">Adminssion Forms</NavLink></li><li className="nav-item me-2"><NavLink className="navbar-brand mr-5" to="/student-support">Student Support</NavLink></li>
                            <li className="nav-item me-2"><NavLink className="navbar-brand mr-5" to="/staff-support">Staff Support</NavLink></li>
                            <li className="nav-item me-2"><NavLink className="navbar-brand mr-5" to="/banner">Banner</NavLink></li>
                            
                            </ul>
                        <div className="d-flex">
                            <ul className="navbar-nav me-5 mb-2 mb-lg-0 nav nav-pills">
                                <li className="nav-item me-2">
                                    <Avatar round={true} size="35" name={role[0]?.name ? role[0]?.name : role[0]?.name === "" ? "Unkown Person" : ""} />
                                </li>
                                <li className="nav-item select dropdown mt-2">
                                    <div className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="text-center">
                                            <span className="fw-bold">{role[0]?.name ? role[0]?.name : role[0]?.name === "" ? "Unkown Person" : ""}</span>
                                        </span>
                                    </div>
                                    <ul className="dropdown-menu menu-side text-start p-2" aria-labelledby="dropdownMenuButton1">
                                        <li className="link-item cp" onClick={() => handleServices()}>
                                            <i className="fa-solid fa-plus h6"></i> &nbsp; Add Services
                                        </li>
                                        <li className="link-item cp" onClick={() => { handleLogout(setToken, setIsLoggedIN) }}>
                                            <i className="fa-solid fa-right-from-bracket h6"></i> &nbsp; Logout
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            <button ref={refOpenServices} type="button" className="btn btn-dark d-none" data-bs-toggle="offcanvas" data-bs-target="#openServices"></button>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="openServices">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Add Services</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refCloseServices}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="serviceName" className="form-label">Service Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="serviceName"
                                    name="serviceName"
                                    autoComplete="off"
                                    placeholder="Eg: Home, About Us"
                                    value={addServices.serviceName}
                                    onChange={changeAddServices}
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="dropDown" className="form-label">Website Position</label>
                                <select className="form-select" id="dropDown" name="dropDown" value={addServices.dropDown} onChange={changeAddServices}>
                                    <option value="" defaultValue>---Select---</option>
                                    <option value="true" defaultValue>True</option>
                                    <option value="false" defaultValue>False</option>
                                </select>
                            </div>
                            <div className="col-md-12">
                                <button type="button" className="btn btn-dark btn-block btn-round" onClick={AddServices} disabled={addServices.serviceName < 1 || addServices.dropDown < 1}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TopNavbar;