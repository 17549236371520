import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

// Navbar
import TopNavbar from "./Components/Navbar/TopNavbar";

// Auth/State
import useToken from "./Components/customHook/useToken";
import Login from "./Components/Authentication/Login";

// Components
import Home from "./Components/Services/Home";
import UploadImages from "./Components/Services/UploadImages";
import Test from "./Components/Services/Test";
import AdmissionsData from "./Components/Services/AdmissionsData";
import StudentSupport from "./Components/Services/StudentSupport";
import StaffSupport from "./Components/Services/StaffSupport";
import CustomTable from "./Components/Custom/CustomTable";
import Banner from "./Components/Services/Banner";

const App = () => {

    const apiURL = process.env.REACT_APP_API_URL_LIVE;

    const { token, setToken } = useToken();
    // eslint-disable-next-line
    const [isLoggedIN, setIsLoggedIN] = useState(false);

    if (!token) {
        return (
            <Login apiURL={apiURL} setToken={setToken} setIsLoggedIN={setIsLoggedIN} />
        );
    }

    return (
        <>
            <div className="App">
                <div className="d-flex flex-column flex-grow-1">
                    <TopNavbar apiURL={apiURL} setToken={setToken} setIsLoggedIN={setIsLoggedIN} />
                    <div className="d-flex flex-grow-1">
                        <Routes>
                            <Route path="/" element={<Home apiURL={apiURL} />} />
                            <Route path="/test" element={<Test />} />
                            <Route path="/uploadImage" element={<UploadImages apiURL={apiURL} />} />
                            <Route path="/admission" element={<AdmissionsData />} />
                            <Route path="/student-support" element={<StudentSupport />} />
                            <Route path="/staff-support" element={<StaffSupport />} />
                            <Route path="/Customtable" element={<CustomTable />} />
                            <Route path="/banner" element={<Banner apiURL={apiURL} />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;