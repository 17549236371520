import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";

const Login = (props) => {

    const { apiURL, setToken, setIsLoggedIN } = props;

    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const [loadingButton, setLoadingButton] = useState(false);
    const navigate = useNavigate();

    const changeCredentials = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }

    const AdminLogin = async (e) => {
        e.preventDefault();
    
        // Set loading button state
        setLoadingButton(true);
    
        // API Call
        try {
            const response = await fetch(`${apiURL}/loginAdmin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: credentials.email,
                    password: credentials.password,
                }),
            });
    
            const data = await response.json();
            setLoadingButton(false);
    
            if (response.status === 200) {
                const swalPromise = swal("Success", data.message, "success");
    
                // Save user data and set authentication state
                secureLocalStorage.setItem("data", JSON.stringify(data));
                setToken("abcdefchijklmnoqprstuvwxyz");
                setIsLoggedIN(true);
    
                // Redirect after a delay if user doesn't press "OK"
                const redirectTimeout = setTimeout(() => {
                    navigate("/");
                }, 5000);
    
                swalPromise.then((ok) => {
                    clearTimeout(redirectTimeout); // Clear the timeout if user presses "OK"
                    if (ok) {
                        navigate("/");
                    }
                });
            } else {
                swal("Error", data.message, "error");
            }
        } catch (error) {
            console.error("Error during login:", error);
            setLoadingButton(false);
            swal("Error", "An unexpected error occurred. Please try again later.", "error");
        }
    };
    

    return (
        <>
            <div className="bg">
                <div className="container vh-100 d-flex justify-content-end align-items-center" style={{ marginRight: "50px" }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mt-5 p-5" style={{ width: "25rem" }}>
                                <form onSubmit={AdminLogin}>
                                    <div className="text-center">
                                        <img src="assets/images/logo.png" alt="naraina-group-of-institutions-logo" style={{ width: "80px" }} />
                                        <legend><b>Sign In</b></legend>
                                    </div>
                                    <div className="form-outline mt-3">
                                        <label className="form-label" htmlFor="email">
                                            <b>Email Id</b>
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            value={credentials.email}
                                            onChange={changeCredentials}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <div className="form-outline mt-3">
                                        <label className="form-label" htmlFor="password">
                                            <b>Password</b>
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="form-control"
                                            value={credentials.password}
                                            onChange={changeCredentials}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-dark btn-block btn-round mt-3">
                                        {loadingButton ? <i className="fa fa-spinner fa-spin"></i> : "Sign in"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;