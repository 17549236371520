import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import DOMPurify from "dompurify";
import JoditEditor from "jodit-react";
import AddTitle from "./AddTitle";
import "./Home.css";
import Questions from "./Questions";

const Home = (props) => {
    const Upload_image_url = process.env.REACT_APP_API_URL_IMAGE;
    const [isLoggedIn, setLoggedIn] = useState(false);


    const { apiURL } = props;
    const [services, setServices] = useState([]);
    const [subservices, setSubServices] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [subServiceId, setSubServiceId] = useState("");

    const [editServices, setEditServices] = useState({ serviceIds: "", eserviceName: "" });
    const [editTitle, setEditTitle] = useState({ subServiceIds: "", etitle: "" });
    const [heading, setHeading] = useState("");
    const [subHeading, setSubHeading] = useState("");
    const [duration, setDuration] = useState("");
    const [overView, setOverview] = useState("");
    const [image, setImage] = useState("");



    const [questions, setQuestions] = useState([]);

    // const [question, setQuestion] = useState("");
    // const [answer, setAnswer] = useState("");
    const [questionAnswer, setQuestionAnswer] = useState([{
        _id: "0", question: "", answer: [
            { subQuestion: "", subAnswer: "", _id: "00" }

        ]
    }])
    // let handleChange = (i, e) => {
    //     console.log(i)
    //     let newQuestionAnswer = [...questionAnswer];
    //     newQuestionAnswer[i][e.target.name] = e.target.value;
    //     console.log(newQuestionAnswer)
    //     setQuestionAnswer(newQuestionAnswer);
    // }
    let handleChange = (i, j, e) => {
        console.log(i, j);
        let newQuestionAnswer = [...questionAnswer];

        if (j === null) {
            // If j is null, update the main question
            newQuestionAnswer[i][e.target.name] = e.target.value;
            newQuestionAnswer[i]['_id'] = `${i}`;
        } else {
            // Otherwise, update the nested subQuestion or subAnswer
            newQuestionAnswer[i].answer[j][e.target.name] = e.target.value;
            newQuestionAnswer[i].answer[j]["_id"] = `${i}${j}`;
        }

        console.log(newQuestionAnswer);
        setQuestionAnswer(newQuestionAnswer);
    };

    let addFormFields = () => {
        setQuestionAnswer([...questionAnswer, {
            question: "", answer: [
                { subQuestion: "", subAnswer: "" }

            ]
        }])
    }
    let addFormsubFields = (i) => {
        let newQuestionAnswer = [...questionAnswer];
        newQuestionAnswer[i].answer.push({ subQuestion: "", subAnswer: "" });
        setQuestionAnswer(newQuestionAnswer);
    };

    let removeFormFields = (i) => {
        let newQuestionAnswer = [...questionAnswer];
        newQuestionAnswer.splice(i, 1);
        setQuestionAnswer(newQuestionAnswer)
    }
    let removeFormsubFields = (i, j) => {
        let newQuestionAnswer = [...questionAnswer];
        newQuestionAnswer[i].answer.splice(j, 1);
        setQuestionAnswer(newQuestionAnswer);
    };



    const [content, setContent] = useState("");
    const editor = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();

    const refOpenUpdateServices = useRef(null);
    const refCloseUpdateServices = useRef(null);
    const refOpenUpdateTitle = useRef(null);
    const refCloseUpdateTitle = useRef(null);
    const refOpenInformation = useRef(null);
    const refCloseInformation = useRef(null);
    const refOpenLongDescription = useRef(null);
    const refCloseLongDescription = useRef(null);

    const GetSubServices = async (id) => {
        // API Call
        await fetch(`${apiURL}/subserviceList/` + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => response.json()).then((data) => {
            if (data.status === true) {
                setSubServices(data.data);
                // console.log("subservices", data.data);

            }
        });
    }

    const GetServices = async () => {

        // API Call
        await fetch(`${apiURL}/serviceList`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => response.json()).then((data) => {
            if (data.status === true) {
                setServices(data.data);


                if (data.data[selectedIndex] !== undefined) {
                    const firstServiceID = data.data[selectedIndex]._id;
                    GetSubServices(firstServiceID);
                }

            }
        });
    }

    const EditServices = async (serviceIds, serviceName) => {
        console.log(serviceName);

        // TODO: API Call
        const response = await fetch(`${apiURL}/updateService/${serviceIds}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ serviceName })
        })

        // eslint-disable-next-line
        const json = await response.json();

        let newCategory = JSON.parse(JSON.stringify(services));

        // Logic to edit in client
        for (let index = 0; index < newCategory.length; index++) {
            const element = newCategory[index];
            if (element._id === serviceIds) {
                newCategory.serviceName = serviceName;
                break;
            }
        }
        setServices(newCategory);
    }

    const handleUpdateService = (currentServices) => {
        refOpenUpdateServices.current.click();
        setEditServices({ serviceIds: currentServices._id, eserviceName: currentServices.service_name });
    }

    const changeServices = (e) => {
        setEditServices({ ...editServices, [e.target.name]: e.target.value });
    }

    const handleEditServices = (e) => {
        e.preventDefault();
        EditServices(editServices.serviceIds, editServices.eserviceName);
        swal("Success", "Services updated successfully", "success").then((ok) => {
            if (ok) {
                refCloseUpdateServices.current.click();
                GetServices();
            }
        });
    }

    const DeleteServices = async (serviceIdss) => {

        // API Call
        const response = await fetch(`${apiURL}/serviceActivation/${serviceIdss}`, {
            method: "PATCH",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
            }
        });

        // eslint-disable-next-line
        // const json = await response.json();

        // const newCategory = services.filter((services) => { return services._id !== serviceIdss })
        // setServices(newCategory);
    }
    const handleDeleteService = async (serviceIdss) => {

        // API Call
        const response = await fetch(`${apiURL}/deleteService/${serviceIdss}`, {
            method: "delete",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
            }
        });
        GetServices()


    }

    const EditTitle = async (subServiceIds, title) => {

        // TODO: API Call
        const response = await fetch(`${apiURL}/updateTitle/${subServiceIds}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ title })
        })

        // eslint-disable-next-line
        const json = await response.json();

        // let newCategory = JSON.parse(JSON.stringify(services[selectedIndex]?.subservices));

        // for (let index = 0; index < newCategory.length; index++) {
        //     const element = newCategory[index];
        //     if (element._id === subServiceIds) {
        //         newCategory.title = title;
        //         break;
        //     }
        // }
        // setServices(newCategory);
    }

    const handleUpdateTitle = (currentTitle) => {
        refOpenUpdateTitle.current.click();
        setEditTitle({ subServiceIds: currentTitle._id, etitle: currentTitle.title });
    }

    const changeTitle = (e) => {
        setEditTitle({ ...editTitle, [e.target.name]: e.target.value });
    }

    const handleEditTitle = (e) => {
        e.preventDefault();
        EditTitle(editTitle.subServiceIds, editTitle.etitle);
        swal("Success", "Title updated successfully", "success").then((ok) => {
            if (ok) {
                window.location.reload();
                refCloseUpdateTitle.current.click();
            }
        });
    }

    const DeleteTitle = async (subServiceIdss) => {

        // API Call
        const response = await fetch(`${apiURL}/activationTitle/${subServiceIdss}`, {
            method: "PATCH",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
            }
        });

        // eslint-disable-next-line
        const json = await response.json();

        if (services[selectedIndex]?.subservices !== undefined) {

            const newCategory = services[selectedIndex]?.subservices.filter((subServices) => { return subServices._id !== subServiceIdss })
            setServices(newCategory);
        }
    }
    const handleDeleteTitle = async (subServiceIdss) => {

        // API Call
        const response = await fetch(`${apiURL}/deleteSubService/${subServiceIdss}`, {
            method: "delete",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
            }
        });
        GetSubServices(subServiceIdss)

    }

    const handleInformation = async (element) => {
        console.log('clicked')
        refOpenInformation.current.click();
        setHeading(element.heading);
        setSubHeading(element.sub_heading);
        setDuration(element.duration);
        setOverview(element.description);
        console.log(element);
        if (element.question_answer !== undefined && element.question_answer !== '') {
            console.log("element.question_answer ", element.question_answer);
            var questionAnswer = await JSON.parse(element.question_answer);
            console.log(questionAnswer)
            setQuestions(questionAnswer)
            setQuestionAnswer(questionAnswer);
        }

    }

    const AddInformation = async (e) => {
        setLoggedIn(true);
        console.log(questions);
        // var question_answer = JSON.stringify(questionAnswer);
        var question_answer = JSON.stringify(questions);
        console.log(question_answer);
        e.preventDefault();

        // return
        // API Call
        const data = new FormData();
        data.append("subServiceId", subServiceId);
        data.append("heading", heading);
        data.append("subHeading", subHeading);
        data.append("duration", duration);
        data.append("overView", overView);
        data.append("image", image);
        data.append("question_answer", question_answer);

        await fetch(`${apiURL}/addInformation`, {
            method: "POST",
            body: data
        }).then((res) => {
            res.json().then((data) => {
                const status = data.status;
                if (status === true) {
                    setLoggedIn(false);
                    swal("Success", data.message, "success").then((ok) => {
                        if (ok) {
                            window.location.reload();
                            refCloseInformation.current.click();
                        }
                    });
                } else {
                    setLoggedIn(true);
                    swal("Error", data.message, "error");
                }
            });
        })
    };

    const handleLongDescription = (element) => {
        refOpenLongDescription.current.click();
        setContent(element.long_description);
    }

    const AddLongDescription = async (e) => {
        e.preventDefault();
        if (!content) {
            swal("Error", 'Please enter the description', "error").then((ok) => {
            });
        } else {


            // API Call
            await fetch(`${apiURL}/addLongDescription`, {
                method: "POST",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    subServiceId: subServiceId,
                    longDescription: content
                })
            }).then((res) => {
                res.json().then((data) => {
                    const status = data.status;
                    if (status === true) {
                        swal("Success", data.message, "success").then((ok) => {
                            if (ok) {
                                refCloseLongDescription.current.click();
                                GetServices();
                            }
                        });
                    } else {
                        swal("Error", data.message, "error");
                    }
                });
            })
        }
    }

    useEffect(() => {
        GetServices();
        // GetSubServices();
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [location]);


    return (
        <>
            <button ref={refOpenUpdateServices} type="button" className="btn btn-dark d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas" aria-controls="depOffCanvas"></button>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="depOffCanvas" aria-labelledby="depOffCanvas">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Update Services</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refCloseUpdateServices}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="eserviceName" className="form-label font-weight-bold">
                                    Service Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="eserviceName"
                                    name="eserviceName"
                                    value={editServices.eserviceName}
                                    onChange={changeServices}
                                    placeholder="Eg: Home, About Us"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-dark btn-block btn-round" onClick={handleEditServices}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <button ref={refOpenUpdateTitle} type="button" className="btn btn-dark d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas1" aria-controls="depOffCanvas1"></button>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="depOffCanvas1" aria-labelledby="depOffCanvas1">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Update Title</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refCloseUpdateTitle}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3">
                            <div className="col-md-12 text-left">
                                <label htmlFor="etitle" className="form-label font-weight-bold">
                                    Title
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="etitle"
                                    name="etitle"
                                    value={editTitle.etitle}
                                    onChange={changeTitle}
                                    placeholder="Ex: Title"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-dark btn-block btn-round" onClick={handleEditTitle}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <button ref={refOpenInformation} type="button" className="btn btn-dark d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas2" aria-controls="depOffCanvas2"></button>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="depOffCanvas2" aria-labelledby="depOffCanvas2" style={{ width: "600px" }}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Update Information</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refCloseInformation}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3" onSubmit={AddInformation}>
                            <div className="col-md-12 text-left">
                                <label htmlFor="heading" className="form-label font-weight-bold">
                                    Heading
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="heading"
                                    name="heading"
                                    value={heading}
                                    onChange={(e) => { setHeading(e.target.value) }}
                                    placeholder="Ex: Heading"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="subHeading" className="form-label font-weight-bold">
                                    Sub Heading
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subHeading"
                                    name="subHeading"
                                    value={subHeading}
                                    onChange={(e) => { setSubHeading(e.target.value) }}
                                    placeholder="Ex: Sub Heading"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="duration" className="form-label font-weight-bold">
                                    Duration
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="duration"
                                    name="duration"
                                    value={duration}
                                    onChange={(e) => { setDuration(e.target.value) }}
                                    placeholder="Ex: Duration"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="overView" className="form-label font-weight-bold">
                                    Over View
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="overView"
                                    name="overView"
                                    value={overView}
                                    onChange={(e) => { setOverview(e.target.value) }}
                                    placeholder="Ex: Over View"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-12 text-left">
                                <label htmlFor="image" className="form-label font-weight-bold">
                                    Upload Image
                                </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="image"
                                    id="image"
                                    onChange={(e) => { setImage(e.target.files[0]) }}
                                    multiple
                                />
                            </div>

                            {/* quick question answer */}

                            <Questions questions={questions} setQuestions={setQuestions} />

                            {/* {questionAnswer.map((element, index) => (
                                <div key={index}>

                                    <div className="col-md-12 text-left">
                                        <label htmlFor="question" className="form-label font-weight-bold">
                                        Question
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="question"
                                            name="question"
                                            value={element.question || ""}
                                            onChange={(e) => { handleChange(index,null, e) }}
                                            placeholder="Question"
                                            autoComplete="off"
                                        />
                                    </div>


                                 
                                    {element?.answer?.map((subItem, j) => (
            <div className="ml-4 child_box" key={j}>
                <div className="col-md-12 text-left">
                                        <label htmlFor="subQuestion" className="form-label font-weight-bold">
                                            Sub Question {j+1}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subQuestion"
                                            id="subQuestion"
                                            value={subItem.subQuestion}
                                            placeholder="Sub Question"
                                            onChange={(e) => handleChange(index, j, e)}
                                            autoComplete="off"
                                        />
                                    </div>
                <div className="col-md-12 text-left">
                                        <label htmlFor="subAnswer" className="form-label font-weight-bold">
                                        Sub Answer {j+1}
                                        </label>
                                        <textarea
                                             type="text"
                                             name="subAnswer"
                                             className="form-control"
                                             id="subAnswer"
                                             value={subItem.subAnswer}
                                             placeholder="Sub Answer"
                                             onChange={(e) => handleChange(index, j, e)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {
                                        j ?
                                            <div className="col-md-12 text-left mt-1">
                                                <button type="button" className="btn btn-danger button remove" onClick={() => removeFormsubFields(index,j)}><i class="fa fa-trash" aria-hidden="true"></i></button>
                                            </div>
                                            : null
                                    }
               
               
            </div>
        ))}
        <div className="col-md-12 text-right mt-1">
                                <button className="btn btn-primary button add" type="button" onClick={() => addFormsubFields(index)}><i class="fa fa-plus" aria-hidden="true"></i></button>

                            </div>

                                    {
                                        index ?
                                            <div className="col-md-12 text-left py-1">
                                                <button type="button" className="btn btn-danger button remove" onClick={() => removeFormFields(index)}>Remove</button>
                                            </div>
                                            : null
                                    }


                                </div>
                            ))} */}
                            {/* <div className="col-md-12 text-left">
                                <button className="btn btn-primary button add" type="button" onClick={() => addFormFields()}>Add</button>

                            </div> */}



                            {/* end */}

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-dark btn-block btn-round">
                                    {isLoggedIn ? 'Uploading...' : 'Submit'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >

            <button ref={refOpenLongDescription} type="button" className="btn btn-dark d-none" data-bs-toggle="offcanvas" data-bs-target="#depOffCanvas3" aria-controls="depOffCanvas3"></button>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="depOffCanvas3" aria-labelledby="depOffCanvas3" style={{ width: "75%" }}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvas1Label">Update Description</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={refCloseLongDescription}></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form className="row g-3" onSubmit={AddLongDescription}>
                            <div className="col-md-12 text-left">
                                <label htmlFor="content" className="form-label font-weight-bold">
                                    Description
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={content}
                                    onChange={newContent => setContent(newContent)}
                                />
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-dark btn-block btn-round">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column">
                <nav className="navbar navbar-expand-lg text-light d-flex flex-column flex-grow-1 py-0 position-relative bg-dark">
                    <div className="container-fluid px-0 mt-0 pt-0" style={{ position: "sticky", top: "0px" }}>
                        <div className="collapse navbar-collapse d-flex flex-column flex-grow-1" id="navbarTogglerDemo01">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-column text-center">
                                {
                                    !services ? "" : services.map((element, index) => {
                                        return (
                                            <li className="nav-item select" key={index} onClick={() => {
                                                setSelectedIndex(index)
                                            }}>
                                                <NavLink key={index} className="font-thin nav-link select2" to="/">
                                                    <div className="hoverable-dropdown">
                                                        <span className="badge bg-dark rounded-pill span-show">
                                                            <div className="btn-group">
                                                                <div
                                                                    className="btn-sm cp"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                </div>
                                                                <div className="dropdown-menu cp ">
                                                                    <div className="dropdown-item"
                                                                        onClick={() => {
                                                                            handleUpdateService(element)
                                                                        }}>
                                                                        Edit
                                                                    </div>
                                                                    <div className="dropdown-item"
                                                                        onClick={() => {
                                                                            handleDeleteService(element._id)
                                                                        }}>
                                                                        Delete
                                                                    </div>
                                                                    <div className="dropdown-item" onClick={() =>
                                                                        swal("Warning", "Are you sure you want to delete this service", "warning", {
                                                                            buttons: ["No", "Yes"]
                                                                        }).then((value) => {
                                                                            if (value) {
                                                                                DeleteServices(element._id);
                                                                                swal("Success", "Services deleted successfully", "success").then((ok) => {
                                                                                    if (ok) {
                                                                                        GetServices();
                                                                                    }
                                                                                });
                                                                            }
                                                                        })}>
                                                                        Active/Deactive
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                        <i className="fa-sharp fa-solid fa-house"></i>
                                                    </div>
                                                    {element.service_name}
                                                </NavLink>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

            <div className="container-fluid">
                <div className="container-fluid mt-5">
                    <div className="row">
                        <div className="col-md-6 text-left">
                            <h4>{services[selectedIndex]?.service_name}</h4>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <NavLink to="/" style={{ fontSize: "18px", color: "black", textDecoration: "none" }}>Dashboard</NavLink>
                                </li>
                                <li className="breadcrumb-item">
                                    <NavLink to="/" style={{ fontSize: "18px", color: "black", textDecoration: "none" }}>{services[selectedIndex]?.service_name}</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 text-right">
                            <AddTitle apiURL={apiURL} serviceId={services[selectedIndex]?._id} />
                        </div>
                    </div>

                    <div className="row">
                        {
                            // services[selectedIndex]?.subservices.length === 0 ? (


                            services.length === 0 ? (
                                <div className="text-center mt-5 mb-5 fw-bold">No data found at the moment!</div>
                            ) : (
                                subservices.map((element, index) => (
                                    <div className="asddsdasad  col-md-4" key={index}>
                                        <div className="my-3">
                                            <div className="card hoverable-dropdown" style={{ height: "500px" }}>
                                                <div style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: "0" }}>
                                                    <span className="badge rounded-pill bg-dark span-hide">{element.sub_service_status}</span>
                                                    <span className="badge bg-dark rounded-pill span-show">
                                                        <div className="btn-group">
                                                            <div
                                                                className="btn-sm cp"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                            </div>
                                                            <div className="dropdown-menu cp">
                                                                <div className="dropdown-item"
                                                                    onClick={() => {
                                                                        handleUpdateTitle(element);
                                                                    } }>
                                                                    Update Title
                                                                </div>
                                                                <div className="dropdown-item"
                                                                    onClick={() => {
                                                                        handleDeleteTitle(element._id);
                                                                    } }>
                                                                    Delete 
                                                                </div>
                                                                <div className="dropdown-item" onClick={() => swal("Warning", "Are you sure you want to delete this title", "warning", {
                                                                    buttons: ["No", "Yes"]
                                                                }).then((value) => {
                                                                    if (value) {
                                                                        DeleteTitle(element._id);
                                                                        swal("Success", "Title deleted successfully", "success").then((ok) => {
                                                                            if (ok) {
                                                                                window.location.reload();
                                                                            }
                                                                        });
                                                                    }
                                                                })}>
                                                                    Deactive
                                                                    {/* Active/Inactive Title */}
                                                                </div>
                                                                <div className="dropdown-item"
                                                                    onClick={() => {
                                                                        handleInformation(element);
                                                                        setSubServiceId(element._id);
                                                                    } }>
                                                                    Update Information
                                                                </div>
                                                                <div className="dropdown-item"
                                                                    onClick={() => {
                                                                        handleLongDescription(element);
                                                                        setSubServiceId(element._id);
                                                                    } }>
                                                                    Update Description
                                                                </div>
                                                                <div className="dropdown-item"
                                                                    onClick={() => {
                                                                        navigate("/uploadImage", { state: { element, from: services[selectedIndex]?.service_name } });
                                                                    } }>
                                                                    Update Images
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                {(element.image_url) ?
                                                    (
                                                        <img src={`${Upload_image_url}/${element.image_url}`} className="card-img-top" style={{ height: "250px" }} alt="No images are available" />

                                                    ) :
                                                    (
                                                        <img src="https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg" className="card-img-top" style={{ height: "250px" }} alt="No images are available" />

                                                    )}
                                                <div className="card-body">
                                                    <h4 className="card-title">{element.title}</h4>
                                                    <h6 className="card-text">{element.sub_heading ? element.sub_heading.slice(0, 50) + "..." : ""}</h6>
                                                    <h6 className="card-text">{element.description ? element.description.slice(0, 70) + "..." : ""}</h6>
                                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.long_description).slice(0, 100) + "..." }}>
                                                    </p>
                                                    <p className="card-text text-right"><small className="text-danger">{moment(element.CreationTime).format("DD/MM/YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;